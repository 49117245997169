export const environment = {
  production: false,
  baseUrl: "https://dev.commandcenter.mymanu.com/backend/api",
  imageUrl: "https://d2neamglna4h3f.cloudfront.net/SubscriptionImages/",
  assets_path: {
    image: './assets/img/',
  },
  gl_flk: '14f78l9',
  cr_chw: '3j2o5p7xy',
  and_mnd: 'abx3y8n',
  sg_rat: 'tlop0cdxy'
};